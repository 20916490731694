import PageSubTitle from "../components/general/PageSubTitle";
import Stats from "../components/home/Stats";
import TopUsers from "../components/home/TopUsers";
import Layout from "../components/Layout";
import Section from "../components/Section";
import useGetBlockchainStat from "../hooks/useGetBlockchainStats";
import useGetStats from "../hooks/useGetStats";

function Main() {
  const stats = useGetStats();
  const cardano = useGetBlockchainStat(["CARDANO"]);
  const bitcoin = useGetBlockchainStat(["BITCOIN", "BTC"]);
  const solana = useGetBlockchainStat(["SOLANA", "SOL"]);
  const ethereum = useGetBlockchainStat(["ETHEREUM", "ETH"]);

  const handleIsBottom = (val) => {
    console.log(val);
  };

  return (
    <Layout handleIsBottom={handleIsBottom}>
      <Section>
        <Stats stats={stats} />
      </Section>

      <Section>
        <PageSubTitle>Cardano</PageSubTitle>
        <TopUsers blockchain={cardano} />
      </Section>

      <Section>
        <PageSubTitle>Bitcoin</PageSubTitle>
        <TopUsers blockchain={bitcoin} />
      </Section>

      <Section>
        <PageSubTitle>Ethereum</PageSubTitle>
        <TopUsers blockchain={ethereum} />
      </Section>

      <Section>
        <PageSubTitle>Solana</PageSubTitle>
        <TopUsers blockchain={solana} />
      </Section>
    </Layout>
  );
}
export default Main;
