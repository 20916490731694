import { useState, useEffect } from "react";

const numberPadding = (number) => {
  let hoursStr = "";
  if (number <= 9) {
    hoursStr = number.toString().padStart(2, "0");
  } else {
    hoursStr = number.toString();
  }
  return hoursStr;
};

const useTimeLapseCounter = (dateString) => {
  // Initialize elapsed time state
  const [elapsedTime, setElapsedTime] = useState("00:00:00");

  // Calculate elapsed time every second
  useEffect(() => {
    // Parse date string into a Date object
    const startDate = new Date(dateString);

    // Set interval to update elapsed time
    const intervalId = setInterval(() => {
      // Calculate elapsed time
      const elapsedTime = new Date() - startDate;

      // Calculate elapsed hours, minutes, and seconds
      const elapsedHours = Math.floor(elapsedTime / (1000 * 60 * 60));
      const elapsedMinutes = Math.floor(
        (elapsedTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const elapsedSeconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

      // Update elapsed time state
      setElapsedTime(
        `${numberPadding(elapsedHours)}:${numberPadding(
          elapsedMinutes
        )}:${numberPadding(elapsedSeconds)}`
      );
    }, 1000);

    // Clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return elapsedTime;
};

export default useTimeLapseCounter;
