function Hashtags({ handleBlockchain }) {
  const hashtags = [
    { name: "Cardano", tags: ["CARDANO", "CNFT", "ADA"] },
    { name: "ERGO", tags: ["ERGO", "ERG"] },
    { name: "Bitcoin", tags: ["BITCOIN", "BTC"] },
    { name: "Solana", tags: ["SOLANA", "SOL"] },
    { name: "Ethereum", tags: ["ETHEREUM", "ETH"] },
  ];

  return (
    <div className="flex flex-wrap justify-start overflow-y-auto gap-2">
      {hashtags.map((tag) => (
        <span
          key={tag.name}
          onClick={() => handleBlockchain(tag.name, tag.tags)}
          className="text-gray-400 border border-gray-100 inline-block py-2 px-3 rounded-md bg-white hover:text-orange-500 text-sm font-medium tracking-widest"
        >
          {tag.name}
        </span>
      ))}
    </div>
  );
}

export default Hashtags;
