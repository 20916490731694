import Layout from "../components/Layout";
import Section from "../components/Section";

function Contact() {
  const handleIsBottom = (val) => {
    console.log(val);
  };

  return (
    <Layout handleIsBottom={handleIsBottom}>
      <Section>PAGE</Section>
    </Layout>
  );
}

export default Contact;
