import React, { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { signInWithPopup, signOut, TwitterAuthProvider } from "firebase/auth";
import { auth } from "../../services/firebase";
import { AuthContext } from "../../context/authContext";
import Branding from "./Branding";
// import Advertisement from "./Advertisement";

export default function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const publicLinks = [
    { title: "Home", uri: "/" },
    { title: "Live Spaces", uri: "/spaces/live" },
    { title: "Ended Spaces", uri: "/spaces/ended" },
    { title: "Scheduled Spaces", uri: "/spaces/scheduled" },
    // { title: "Widget", uri: "/widget" },
  ];
  const privateLinks = [
    { title: "Settings", uri: "/user/settings" },
    { title: "Contact", uri: "/contact" },
  ];

  const handleLogin = () => {
    const twitterAuth = new TwitterAuthProvider();

    signInWithPopup(auth, twitterAuth)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(errorCode, errorMessage);
      });
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(errorCode, errorMessage);
      });
  };

  return (
    <nav>
      <div className="mt-9">
        <Branding />
      </div>
      <div className="flex flex-col">
        <ul className="h-96 space-y-9 mt-20 mb-20 font-medium text-xl">
          {/* start public links */}
          {publicLinks.map((link) => (
            <li
              key={link.title}
              className={
                location.pathname === link.uri
                  ? "border-l-4 border-orange-500"
                  : "border-l-4 border-white hover:border-l-4 hover:border-orange-500"
              }
            >
              <Link className="px-8" to={link.uri}>
                {link.title}
              </Link>
            </li>
          ))}
          {/* end public links */}

          {/* start private links */}
          {user ? (
            <>
              {privateLinks.map((link) => (
                <li
                  key={link.title}
                  className={
                    location.pathname === link.uri
                      ? "border-l-4 border-orange-500"
                      : "border-l-4 border-white hover:border-l-4 hover:border-orange-500"
                  }
                >
                  <Link className="px-8" to={link.uri}>
                    {link.title}
                  </Link>
                </li>
              ))}
            </>
          ) : null}
          {/* end private links */}

          {/* start auth links */}
          <li className="border-l-4 border-white hover:border-l-4 hover:border-orange-500">
            {user ? (
              <button className="px-8" onClick={handleLogout}>
                Logout
              </button>
            ) : (
              <button className="px-8" onClick={handleLogin}>
                Login
              </button>
            )}
          </li>
          {/* end auth links */}
        </ul>
        {/* <Advertisement /> */}
      </div>
    </nav>
  );
}
