import { useEffect, useState } from "react";
import { getDocument } from "../utilities/firebase";

function useGetStats() {
  const [spaces, setSpaces] = useState({});

  useEffect(() => {
    getDocument("stats", "spaces")
      .then((firestore) => {
        setSpaces({ ...spaces, ...firestore });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return spaces;
}

export default useGetStats;
