import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Branding from "./Branding";
import Navigation from "./Navigation";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu((current) => !current);
  };

  return (
    <>
      {/* larger screens */}
      <header className="w-80 flex-none bg-white hidden lg:block">
        <Navigation />
      </header>

      {/* smaller screens */}
      <header className="bg-white block lg:hidden">
        <nav className="container bg-white">
          <div className="flex items-center justify-between px-4 py-5 mx-auto">
            <Branding />
            <div className="flex lg:hidden">
              <div onClick={handleShowMenu} className="space-y-2">
                <span className="block w-8 h-0.5 bg-gray-600 animate-pulse"></span>
                <span className="block w-8 h-0.5 bg-gray-600 animate-pulse"></span>
                <span className="block w-8 h-0.5 bg-gray-600 animate-pulse"></span>
              </div>
            </div>
          </div>
          <div
            className={`${
              showMenu
                ? "flex flex-col p-5 gap-5 text-center min-h-screen h-max overscroll-none"
                : "hidden"
            }`}
          >
            <Link className="text-3xl font-bold" to="/">
              Home
            </Link>
            <Link className="text-3xl font-bold" to="/spaces/live">
              Live Spaces
            </Link>
            <Link className="text-3xl font-bold" to="/spaces/scheduled">
              Scheduled Spaces
            </Link>
            <Link className="text-3xl font-bold" to="/spaces/ended">
              Ended Spaces
            </Link>
            <Link className="text-3xl font-bold" to="/settings">
              Settings
            </Link>
            <Link className="text-3xl font-bold" to="#">
              Login
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
}
