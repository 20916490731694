import { useEffect, useState } from "react";
import { getDocumentsByTags } from "../utilities/firebase";

function useGetBlockchainStat(tags) {
  const [cardanoStats, setCardanoStats] = useState({});

  useEffect(() => {
    getDocumentsByTags(
      "/stats/spaces/hosts",
      tags,
      "participant_count",
      "desc",
      10
    )
      .then((firestore) => {
        setCardanoStats((cardanoStats) => ({
          ...cardanoStats,
          hosts: firestore.allData,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getDocumentsByTags(
      "/stats/spaces/speakers",
      tags,
      "space_count",
      "desc",
      10
    )
      .then((firestore) => {
        setCardanoStats((cardanoStats) => ({
          ...cardanoStats,
          speakers: firestore.allData,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return cardanoStats;
}

export default useGetBlockchainStat;
