import React from "react";

const DrawerUsers = ({ space, title }) => {
  return (
    <>
      {space ? (
        <div className="bg-white rounded-md shadow-sm mt-8 p-8">
          <h1 className="text-2xl uppercase font-semibold mb-5">
            {title} ({space ? space.length : "0"})
          </h1>
          {space.map((user) => (
            <div
              // the userid is unique but if a user schedules two spaces
              // there is a key conflict without random
              key={`${Math.random()}${user.id}`}
              className="flex items-center space-x-4 border-t py-6"
            >
              <img
                className="rounded-full ring ring-black w-20 h-20"
                src={user.profile_image_url.replace("_normal", "")}
                alt="profile"
              />
              <div>
                <a
                  href={`https://twitter.com/${user.username}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex flex-col">
                    <h1 className="font-bold">{user.name}</h1>
                    <h1 className="font-bold">@{user.username}</h1>
                  </div>
                </a>
                <p className="text-xs">{user.description}</p>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default DrawerUsers;
