const numberPadding = (number) => {
  let hoursStr = "";
  if (number <= 9) {
    hoursStr = number.toString().padStart(2, "0");
  } else {
    hoursStr = number.toString();
  }
  return hoursStr;
};

function useTimeLapse(startAt, endAt) {
  const startDate = new Date(startAt);
  const endDate = new Date(endAt);
  const elapsedTime = startDate - endDate;

  const elapsedHours = Math.floor(elapsedTime / (1000 * 60 * 60));
  const elapsedMinutes = Math.floor(
    (elapsedTime % (1000 * 60 * 60)) / (1000 * 60)
  );
  const elapsedSeconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

  return `${numberPadding(elapsedHours)}:${numberPadding(
    elapsedMinutes
  )}:${numberPadding(elapsedSeconds)}`;
}

export default useTimeLapse;
