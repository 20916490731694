import DrawerUsers from "./DrawerUsers";

function Drawer({ isOpen, handleCloseDrawer, space }) {
  return (
    <div
      className={
        "fixed h-full w-full z-50 left-0 top-0 transform ease-in-out" +
        (isOpen
          ? // ? " bg-black bg-opacity-50 z[-1]"
            " bg-black bg-opacity-75 transition-opacity opacity-100 duration-500 translate-x-0"
          : // : " transition-none opacity-0 translate-x-full")
            " transition-opacity opacity-0 translate-x-full")
      }
    >
      <div className="mx-auto p-5 overflow-y-auto bg-[#dfe5e8] h-full w-full lg:w-1/3 sm:fixed sm:right-0 sm:top-0 sm:shadow-lg">
        {/* close button start */}
        <div className="absolute right-4 top-4">
          <button
            className="border-2 border-black rounded-full"
            onClick={() => {
              handleCloseDrawer(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-activity"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        {/* close button end */}

        {/* start host */}
        <div className="bg-white rounded-md shadow-sm mt-8 p-8">
          <div className="flex items-center space-x-4">
            <img
              className="rounded-full ring ring-green-600 w-24 h-24"
              src={
                space
                  ? space.creator_id.profile_image_url
                      .replace("_normal", "")
                      .replace("_normal", "")
                  : ""
              }
              alt="avatar"
            />
            <div className="flex flex-col">
              <h1 className="font-bold text-lg">@{space ? space.title : ""}</h1>
              <h1 className="font-bold text-lg">
                @{space ? space.creator_id.username : ""}
              </h1>
            </div>
          </div>
        </div>
        {/* end host */}

        <DrawerUsers space={space.host_ids} title="Co-Hosts" />
        <DrawerUsers space={space.invited_user_ids} title="Guests" />
        <DrawerUsers space={space.speaker_ids} title="Speakers" />
      </div>
    </div>
  );
}

export default Drawer;
