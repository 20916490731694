function SpaceCard({ title, space }) {
  return (
    <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
      <div className="bg-white rounded-md shadow-sm p-5 px-4 py-6">
        <h2 className="title-font font-bold text-7xl text-black">{space}</h2>
        <p className="leading-snug text-xl font-medium">{title} Spaces</p>
      </div>
    </div>
  );
}

export default SpaceCard;
