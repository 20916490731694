import { useState } from "react";

import PageSubTitle from "../../components/general/PageSubTitle";
import PageTitle from "../../components/general/PageTitle";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import Hashtags from "../../components/spaces/Hashtags";
import Listings from "../../components/spaces/Listings";

import useGetSpaces from "../../hooks/useGetSpaces";

function Scheduled() {
  const [isBottom, setIsBottom] = useState(false);
  const [blockchain, setBlockchain] = useState(["CARDANO", "CNFT", "ADA"]);
  const [pageTitle, setPageTitle] = useState("Cardano");
  const data = useGetSpaces(
    "scheduled",
    "scheduled_start",
    "asc",
    isBottom,
    blockchain
  );

  const handleIsBottom = (val) => {
    setIsBottom(val);
  };

  const handleBlockchain = (title, chain) => {
    setPageTitle(title);
    setBlockchain(chain);
  };

  return (
    <Layout handleIsBottom={handleIsBottom}>
      <Section>
        <PageTitle>Scheduled Spaces</PageTitle>
      </Section>

      <Section>
        <Hashtags handleBlockchain={handleBlockchain} />
      </Section>

      <Section>
        <PageSubTitle>{pageTitle}</PageSubTitle>
        <Listings data={data} />
      </Section>
    </Layout>
  );
}

export default Scheduled;
