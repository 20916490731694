import PageTitle from "../../components/general/PageTitle";
import PageSubTitle from "../../components/general/PageSubTitle";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import Listings from "../../components/spaces/Listings";

import useGetLiveSpaces from "../../hooks/useGetLiveSpaces";

function Live() {
  const cardanoSpaces = useGetLiveSpaces(["CARDANO", "CNFT", "ADA"], 25);
  const ergoSpaces = useGetLiveSpaces(["ERGO", "ERG"], 25);
  const bitcoinSpaces = useGetLiveSpaces(["BITCOIN", "BTC"], 25);
  const ethereumSpaces = useGetLiveSpaces(["ETHEREUM", "ETH"], 25);
  const solanaSpaces = useGetLiveSpaces(["SOLANA", "SOL"], 25);

  const handleIsBottom = (val) => {
    console.log(val);
  };

  return (
    <Layout handleIsBottom={handleIsBottom}>
      <Section>
        <PageTitle>Live Spaces</PageTitle>
      </Section>

      {cardanoSpaces.length > 0 ? (
        <Section>
          <div className="mb-5">
            <PageSubTitle>Cardano</PageSubTitle>
          </div>
          <Listings data={cardanoSpaces} />
        </Section>
      ) : null}

      {ergoSpaces.length > 0 ? (
        <Section>
          <div className="mb-5">
            <PageSubTitle>Ergo</PageSubTitle>
          </div>
          <Listings data={ergoSpaces} />
        </Section>
      ) : null}

      {bitcoinSpaces.length > 0 ? (
        <Section>
          <div className="mb-5">
            <PageSubTitle>Bitcoin</PageSubTitle>
          </div>
          <Listings data={bitcoinSpaces} />
        </Section>
      ) : null}

      {ethereumSpaces.length > 0 ? (
        <Section>
          <div className="mb-5">
            <PageSubTitle>Ethereum</PageSubTitle>
          </div>
          <Listings data={ethereumSpaces} />
        </Section>
      ) : null}

      {solanaSpaces.length > 0 ? (
        <Section>
          <div className="mb-5">
            <PageSubTitle>Solana</PageSubTitle>
          </div>
          <Listings data={solanaSpaces} />
        </Section>
      ) : null}
    </Layout>
  );
}

export default Live;
