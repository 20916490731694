import { useRef, useState } from "react";
import Header from "../components/header/Header";

function Layout({ children, handleIsBottom }) {
  const [scrollUp, setScrollUp] = useState(0);
  const listInnerRef = useRef();

  const handleScrollToTop = () => {
    listInnerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScrollToBottom = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (Math.round(scrollTop + clientHeight) === scrollHeight) {
        handleIsBottom(true);
        setScrollUp(true);
      } else {
        handleIsBottom(false);
        setScrollUp(false);
      }

      setScrollUp(scrollTop);
    }
  };

  return (
    <div className="block lg:flex min-h-screen h-screen">
      <Header />
      <main
        className="flex-1 overflow-y-auto bg-[#eef4f9] shadow-sm"
        onScroll={handleScrollToBottom}
        ref={listInnerRef}
      >
        {children}

        {/* Start scroll to top button */}
        {scrollUp > 0 ? (
          <span
            onClick={handleScrollToTop}
            className="absolute bottom-7 right-2 bg-orange-500 rounded-full w-10 h-10 p-2 hover:bg-orange-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#eef4f9"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="12" y1="20" x2="12" y2="4"></line>
              <polyline points="6 10 12 4 18 10"></polyline>
            </svg>
          </span>
        ) : null}
        {/* End scroll to top button */}
      </main>
    </div>
  );
}

export default Layout;
