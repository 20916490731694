import { db } from "../services/firebase";

import {
  collection,
  query,
  onSnapshot,
  orderBy,
  limit,
  where,
  startAfter,
  getDocs,
  getDoc,
  setDoc,
  doc,
} from "firebase/firestore";

const saveDocument = (collection, data) => {
  function uuidv4() {
    // source https://stackoverflow.com/a/2117523/1320596
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  setDoc(doc(db, collection, uuidv4()), data);
};

/**
 * Retrieves a single document.
 * @param {*} collectionName
 * @param {*} documentName
 * @returns
 */
const getDocument = async (collectionName, documentName) => {
  try {
    const docRef = doc(db, collectionName, documentName);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (error) {
    console.log("getDoc Error", error);
  }
};

/**
 * Retrieves a set of documents
 * @param {*} collectionName
 * @param {*} propertyOrder
 * @param {*} directionOrder
 * @param {*} limitValue
 * @returns
 */
const getDocuments = async (
  collectionName,
  propertyOrder,
  directionOrder,
  limitValue
) => {
  try {
    const documentSnapshots = await getDocs(
      query(
        collection(db, collectionName),
        orderBy(propertyOrder, directionOrder),
        limit(limitValue)
      )
    );
    const allData = [];
    const key = documentSnapshots.docs[documentSnapshots.docs.length - 1];

    documentSnapshots.forEach((doc) => {
      allData.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return { allData, key };
  } catch (error) {
    console.log(error);
  }
};

/**
 * Retrieves a set of documents
 * @param {*} collectionName
 * @param {*} limitValue
 * @returns
 */
const getAllDocuments = async (collectionName, limitValue) => {
  try {
    const documentSnapshots = await getDocs(
      query(collection(db, collectionName), limit(limitValue))
    );
    const allData = [];

    documentSnapshots.forEach((doc) => {
      allData.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return allData;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Retrieves a batch of documents starting from the point of a given query cursor.
 * @param {*} collectionName
 * @param {*} propertyOrder
 * @param {*} directionOrder
 * @param {*} lastDocument
 * @param {*} limitValue
 * @returns
 */
const getMoreDocuments = async (
  collectionName,
  propertyOrder,
  directionOrder,
  limitValue,
  cursor
) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, collectionName),
        orderBy(propertyOrder, directionOrder),
        startAfter(cursor),
        limit(limitValue)
      )
    );
    const allData = [];
    const key = querySnapshot.docs[querySnapshot.docs.length - 1];

    querySnapshot.forEach((doc) => {
      allData.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return { allData, key };
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param {*} collectionName
 * @param {*} tags
 * @param {*} propertyOrder
 * @param {*} directionOrder
 * @param {*} limitValue
 * @returns
 */
const getDocumentsByTags = async (
  collectionName,
  tags,
  propertyOrder,
  directionOrder,
  limitValue
) => {
  try {
    const documentSnapshots = await getDocs(
      query(
        collection(db, collectionName),
        where("tags", "array-contains-any", tags),
        orderBy(propertyOrder, directionOrder),
        limit(limitValue)
      )
    );
    const allData = [];
    const key = documentSnapshots.docs[documentSnapshots.docs.length - 1];

    documentSnapshots.forEach((doc) => {
      allData.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return { allData, key };
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param {*} collectionName
 * @param {*} tags
 * @param {*} propertyOrder
 * @param {*} directionOrder
 * @param {*} limitValue
 * @param {*} cursor
 * @returns
 */
const getMoreDocumentsByTags = async (
  collectionName,
  tags,
  propertyOrder,
  directionOrder,
  limitValue,
  cursor
) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, collectionName),
        where("tags", "array-contains-any", tags),
        orderBy(propertyOrder, directionOrder),
        startAfter(cursor),
        limit(limitValue)
      )
    );
    const allData = [];
    const key = querySnapshot.docs[querySnapshot.docs.length - 1];

    querySnapshot.forEach((doc) => {
      allData.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return { allData, key };
  } catch (error) {
    console.log(error);
  }
};

const getRealtimeDocuments = (
  collectionName,
  propertyOrder,
  directionOrder,
  limitValue
) => {
  let allData = [];
  return new Promise((resolve) => {
    const q = query(
      collection(db, collectionName),
      orderBy(propertyOrder, directionOrder),
      limit(limitValue)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(doc.data().participant_count);
        allData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      resolve(allData);
    });
  });
};

export {
  saveDocument,
  getDocument,
  getDocuments,
  getAllDocuments,
  getMoreDocuments,
  getDocumentsByTags,
  getMoreDocumentsByTags,
  getRealtimeDocuments,
};
