import { useEffect, useState } from "react";
import {
  getDocumentsByTags,
  getMoreDocumentsByTags,
} from "../utilities/firebase";

function useGetSpaces(collection, parameter, direction, isBottom, blockchain) {
  const [data, setData] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);

  console.log(collection, parameter, direction, isBottom, blockchain);
  useEffect(() => {
    getDocumentsByTags(collection, blockchain, parameter, direction, 10)
      .then((firestore) => {
        console.log(firestore.allData);
        setData(firestore.allData);
        setLastDoc(firestore.key);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [blockchain]);

  useEffect(() => {
    if (isBottom && lastDoc) {
      getMoreDocumentsByTags(
        collection,
        blockchain,
        parameter,
        direction,
        10,
        lastDoc
      )
        .then((firestore) => {
          setData([...data, ...firestore.allData]);
          setLastDoc(firestore.key);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isBottom, blockchain]);

  return data;
}

export default useGetSpaces;
