function useDateString(dateString) {
  // return new Date(dateString).toLocaleString().split(",")[0];
  const date = new Date(dateString);
  // Get the month and day
  let month = date.toLocaleString("default", { month: "short" });
  let day = date.getDate();
  let hour = date.toLocaleString("default", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // Format the output string
  let result = `${month} ${day} UTC ${hour}`;

  return result;
}

export default useDateString;
