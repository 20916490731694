function UserCard({ users, hideData, cardType }) {
  return (
    <div>
      {users &&
        users.map((user) => (
          <div
            key={`${cardType}-${user.id}`}
            className="mt-3 bg-white rounded-md shadow-sm p-5"
          >
            <div className="flex">
              <img
                className="inline-block rounded-full ring-2 ring-black w-12 h-12"
                src={user.profile_image_url}
                alt="profile"
              />
              <div className="flex-1 ml-4">
                <div className="text-md font-semibold">{user.name}</div>
                <div>@{user.username}</div>
              </div>

              <div className="flex space-x-5 text-center justify-end items-end">
                <div>
                  <h1 className="text-md font-semibold">{user.space_count}</h1>
                  <h2 className="text-md text-gray-400 font-semibold">
                    Spaces
                  </h2>
                </div>
                {!hideData ? (
                  <div>
                    <h1 className="text-md font-semibold">
                      {user.participant_count}
                    </h1>
                    <h2 className="text-md text-gray-400 font-semibold">
                      Participants
                    </h2>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default UserCard;
