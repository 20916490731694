import { db } from "../services/firebase";
import {
  collection,
  query,
  orderBy,
  where,
  onSnapshot,
  limit,
} from "firebase/firestore";

import { useState, useEffect } from "react";

function useGetLiveSpaces(tags, limitNumber) {
  const [data, setData] = useState([]);

  useEffect(() => {
    let q = query(
      collection(db, "live"),
      where("tags", "array-contains-any", tags),
      orderBy("participant_count", "desc"),
      limit(limitNumber)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const spaces = snapshot.docs.map((doc) => doc.data());
      setData(spaces);
    });

    return () => unsubscribe();
  }, []);

  return data;
}

export default useGetLiveSpaces;
