import Layout from "../../components/Layout";
import Section from "../../components/Section";

function UserSettings() {
  const handleIsBottom = (val) => {
    console.log(val);
  };

  return (
    <Layout handleIsBottom={handleIsBottom}>
      <Section>PAGE</Section>
    </Layout>
  );
}

export default UserSettings;
