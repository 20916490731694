import { useState } from "react";
import useDateString from "../../hooks/useDateString";
import useTimeLapse from "../../hooks/useTimeLapse";
import useTimeLapseCounter from "../../hooks/useTimeLapseCounter";
import Drawer from "./Drawer";

function Card({ space }) {
  const [isOpen, setIsOpen] = useState(false);
  const liveTimeLapse = useTimeLapseCounter(space.started_at);
  const endedTimeLapse = useTimeLapse(space.ended_at, space.started_at);
  const scheduledTimeLapse = useDateString(space.scheduled_start);
  let avatarRingColor = "";

  if (space.state === "live") {
    avatarRingColor =
      "ring-green-500 animate-pulse duration-1s ease-in-out infinite";
  } else {
    avatarRingColor = "ring-black";
  }

  const handleCloseDrawer = (state) => {
    setIsOpen(state);
  };

  const handleOpenDrawer = (state) => {
    setIsOpen(state);
  };

  return (
    <div>
      {/* start top card */}
      <div className="mt-3 bg-white rounded-t-md shadow-sm">
        <div className="block md:flex justify-between text-center p-5">
          <div className="flex">
            {/* avatar */}
            <div className="hidden lg:flex relative">
              <img
                className="w-14 h-14 absolute rounded-full"
                src={space.creator_id.profile_image_url}
                alt="avatar"
              />
              <div
                className={`w-14 h-14  rounded-full ring-2 ${avatarRingColor}`}
              ></div>
            </div>

            {/* info */}
            <div className="ml-0 pr-5 md:ml-4 md:mr-4 md:text-start">
              <p className="text-xl font-bold lg:text-lg">{space.title}</p>
              <p className="text-md font-normal mt-1 lg:mt-0 lg:text-md">
                <a
                  className="hover:text-orange-500"
                  href={`https://twitter.com/${space.creator_id.username}`}
                >
                  @{space.creator_id.username} - {space.creator_id.name}
                </a>
              </p>
            </div>
          </div>

          {/* start stats */}
          <div className="flex justify-between items-center space-x-12 text-center mt-4 md:mt-0">
            {space.state !== "scheduled" ? (
              <>
                <div>
                  <h1 className="text-lg font-bold">
                    {(space.speaker_ids && space.speaker_ids.length) || "0"}
                  </h1>
                  <h2 className="text-gray-400 text-md font-semibold">
                    Speakers
                  </h2>
                </div>
                <div>
                  <h1 className="text-lg font-bold">
                    {space.participant_count}
                  </h1>
                  <h2 className="text-gray-400 text-md font-semibold">
                    Participants
                  </h2>
                </div>
              </>
            ) : null}
            <div>
              <h1
                className="text-lg font-bold tracking-wider"
                // style={{fontFamily: "Helvetica",}}
              >
                {space.state === "scheduled" ? scheduledTimeLapse : ""}
                {space.state === "live" ? liveTimeLapse : ""}
                {space.state === "ended" ? endedTimeLapse : ""}
              </h1>
              <h2 className="text-gray-400 text-md font-semibold">
                {space.state === "scheduled" ? "Start" : ""}
                {space.state === "live" ? "Elapsed" : ""}
                {space.state === "ended" ? "Elapsed " : ""}
              </h2>
            </div>
          </div>
          {/* end stats */}
        </div>
      </div>
      {/* end top card */}

      {/* start bottom card */}
      <div className="bg-white px-5 py-3 border-t border-gray-100 rounded-b-md shadow-sm">
        <div className="flex justify-between">
          <div className="flex space-x-8 md:space-x-5">
            <div className="flex space-x-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#9CA3AF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-activity"
              >
                <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
              </svg>
              <p className="text-gray-400 font-semibold text-sm hover:text-orange-500">
                <a
                  href={`https://twitter.com/i/spaces/${space.id}/peek`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Listen
                </a>
              </p>
            </div>
            <div className="flex space-x-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#9CA3AF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-activity"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12" y2="8"></line>
              </svg>
              <p
                onClick={() => handleOpenDrawer(true)}
                className="text-gray-400 font-semibold text-sm hover:text-orange-500"
              >
                Information
              </p>
            </div>
          </div>

          <div className="hidden md:space-x-3 md:flex md:overflow-x-auto md:pl-32">
            {space &&
              space.tags &&
              space.tags.map((tag) => (
                <span
                  key={`hashtag-${space.id}-${Math.random()}`}
                  className="text-gray-400 inline-block py-2 px-3 rounded-md border border-gray-100 bg-[#f4faff] hover:text-orange-500 text-sm font-medium tracking-widest"
                >
                  #{tag}
                </span>
              ))}
          </div>
        </div>
      </div>
      {/* end bottom card */}

      <Drawer
        isOpen={isOpen}
        handleCloseDrawer={handleCloseDrawer}
        space={space}
      />
    </div>
  );
}

export default Card;
