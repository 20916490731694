import UserCard from "../../components/home/UserCard";

function TopUsers({ blockchain }) {
  return (
    <div className="flex flex-wrap -m-4">
      <div className="p-4 w-full lg:w-1/2">
        <h2 className="text-xl text-gray-400 font-semibold text-center md:text-start">
          Top Hosts
        </h2>
        <UserCard users={blockchain.hosts} hideData={false} cardType="host" />
      </div>

      <div className="p-4 w-full lg:w-1/2">
        <h2 className="text-xl text-gray-400 font-semibold text-center md:text-start">
          Top Speakers
        </h2>
        <UserCard
          users={blockchain.speakers}
          hideData={true}
          cardType="speaker"
        />
      </div>
    </div>
  );
}

export default TopUsers;
