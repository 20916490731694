import { useState } from "react";
import Card from "./Card";

function Listings({ data }) {
  const [filterTerms, setFilterTerms] = useState("");

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-xl text-gray-400 font-semibold hidden lg:block">
          {data && data.length} Spaces
        </h2>
        <div className="relative w-full lg:w-96">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-400 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            className="block h-12 w-full lg:w-96 p-4 pl-10 text-sm border border-gray-200 rounded-md bg-white outline-none focus:border-orange-500 transition-colors duration-200 ease-in-out"
            placeholder="Search"
            value={filterTerms}
            onChange={(event) => setFilterTerms(event.target.value)}
          />
        </div>
      </div>

      {data &&
        data
          .filter(
            (item) =>
              (item.title &&
                item.title.toLowerCase().includes(filterTerms.toLowerCase())) ||
              item.creator_id.username
                .toLowerCase()
                .includes(filterTerms.toLowerCase()) ||
              item.creator_id.name
                .toLowerCase()
                .includes(filterTerms.toLowerCase())
          )
          .map((space) => (
            <Card key={`${space.id}${space.creator_id.id}`} space={space} />
          ))}
    </>
  );
}

export default Listings;
