import { Routes, Route } from "react-router-dom";

import "./App.css";

import Main from "./pages/Main";
import Live from "./pages/spaces/Live";
import Ended from "./pages/spaces/Ended";
import Scheduled from "./pages/spaces/Scheduled";
import UserSettings from "./pages/user/Settings";
import Contact from "./pages/Contact";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      {/* Spaces Routes */}
      <Route path="/spaces/live" element={<Live />} />
      <Route path="/spaces/ended" element={<Ended />} />
      <Route path="/spaces/scheduled" element={<Scheduled />} />
      {/* User Routes */}
      <Route path="/user/settings" element={<UserSettings />} />
      {/* General Routes */}
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
